@import 'bootstrap/dist/css/bootstrap.min.css';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
    overflow-y: hidden;
    overflow-x: hidden;
}

#root {
    overflow-y: hidden;
    overflow-x: hidden;
}
