@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import 'react-datepicker/dist/react-datepicker-cssmodules.css';

.appname {
    color: white;
    margin-top: 10px;
    line-height: 8vh;
    font-weight: bold;
}

.salir {
    color: white;
    margin-top: 10px;
    line-height: 8vh;
    cursor: pointer;
    text-align: right;
}

.salir:hover {
    color: #26f6ff;
}

.in-app-btn {
    margin-top: calc(10px + 1vh);
    height: 6vh;
    font-size: 1rem;
    padding: 0.35rem;
    padding-left: 10px;
    padding-right: 10px;
    text-align: right;
}

.main-container {
    height: 100vh;
    overflow-y: auto !important;
}

.card-margin {
    margin-bottom: 20px;
}

.card-hover {
    cursor: pointer;
    transition: box-shadow 0.5s ease;
}

.card-hover:hover {
    -webkit-box-shadow: 0px 0px 41px 2px #13dbe2;
    -moz-box-shadow: 0px 0px 41px 2px #13dbe2;
    box-shadow: 0px 0px 41px 2px #13dbe2;
}

.header-row {
    margin-bottom: 20px;
}

.new-project {
    background-color: rgb(230, 230, 230);
    color: rgb(138, 138, 138);
}

.align-right {
    float: right;
}

.card-hover-no-click {
    transition: box-shadow 0.5s ease;
}

.card-hover-no-click:hover {
    -webkit-box-shadow: 0px 0px 41px 2px #13dbe2;
    -moz-box-shadow: 0px 0px 41px 2px #13dbe2;
    box-shadow: 0px 0px 41px 2px #13dbe2;
}

.card-columns {
    @include media-breakpoint-only(xs) {
        column-count: 1;
    }
    @include media-breakpoint-only(sm) {
        column-count: 2;
    }
    @include media-breakpoint-only(md) {
        column-count: 3;
    }
    @include media-breakpoint-only(lg) {
        column-count: 4;
    }
    @include media-breakpoint-only(xl) {
        column-count: 4;
    }
  }

.icon {
    cursor: pointer;
    margin: 5px;
    font-size: 1.25rem;
}

.react-datepicker-wrapper {
    width: 100%;

    div {
        width: 100%;

        input {
            width: 100%;

            display: block;
            width: 100%;
            height: calc(1.5em + .75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
    }
}

.invalid-field {
    border-color: red;
}

.invalid-field:focus {
    border-color: red;
    outline: red;
}

.abs-auth-error {
    font-family: 'Poppins';
    text-align: center;
    font-size: 1.10rem;
    margin-top: 15px;
    font-weight: 500;
    color: red;
}

.abs-auth-control {
    font-family: 'Poppins';
    padding: 15px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.striked {
    text-decoration: line-through;
}