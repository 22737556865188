@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/variables";


.abs-auth-background {
    height: 100vh;
    width: 100vw;
    background-image: url('../../Assets/Background/nature001.jpg');
    background-size: cover;
    background-position: center;
}

.abs-auth-content {
    width: 100%;
    background-color: white;
    min-height: 10vh;
    border-radius: 15px;
    padding: 25px;
    max-height: calc(100vh - 30px);
    overflow-y: auto;

    @include media-breakpoint-up(xl) {
        width: 80%;
        display: block;
        margin: auto;
    }
}

.abs-auth-row {
    height: 100vh;
    padding: 15px;
}

.abs-auth-logo-container {
    display: block;
    margin: auto;
    width: 50%;

    @include media-breakpoint-down(sm) {
        width: 60%;
    }

    @include media-breakpoint-down(md) {
        width: 30%;
    }

    @include media-breakpoint-up(lg) {
        width: 40%;
    }
}

.abs-auth-logo-container-large {
    display: block;
    margin: auto;
    width: 30%;

    @include media-breakpoint-down(sm) {
        width: 60%;
    }

    @include media-breakpoint-down(md) {
        width: 30%;
    }

    @include media-breakpoint-up(lg) {
        width: 25%;
    }

}

.abs-auth-header {
    font-family: 'Poppins';
    text-align: center;
    font-size: 1.75rem;
    margin-top: 15px;
    font-weight: 600;
    color: #022039;
}

.abs-auth-error {
    font-family: 'Poppins';
    text-align: center;
    font-size: 1.10rem;
    margin-top: 15px;
    font-weight: 500;
    color: red;
}

.abs-auth-label {
    font-family: 'Poppins';
}

.abs-auth-control {
    font-family: 'Poppins';
    padding: 15px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.abs-auth-spacer {
    height: 10px;
}

.abs-auth-button {
    background-color: #26f6ff;
    color: #022039;
    border-radius: 5px;
    border: 0;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Poppins';
    outline: none;
    padding: 10px 20px;
    margin: auto;
    display: block;
}

.abs-auth-button:hover {
    background-color: #13dbe2;
    color: #022039;
}

.abs-auth-button:focus {
    outline: none;
}

.abs-auth-button:active {
    background-color: #13dbe2 !important;
    color: #022039 !important;
}

.abs-auth-button-secondary {
    background-color: #fff;
    color: #022039;
    border-radius: 5px;
    border: 0;
    font-family: 'Poppins';
    outline: none;
    padding: 10px 20px;
    margin: auto;
    display: block;
    text-decoration: none !important;
}

.abs-auth-button-secondary:hover {
    background-color: #dddddd;
    color: #022039;
    text-decoration: none !important;
}

.abs-auth-button-secondary:focus {
    outline: none;
    background-color: #dddddd;
}


.abs-auth-button-secondary:active {
    outline: none !important;
    color: #022039 !important;
    background-color: #dddddd !important;
    border: 0 !important;
}

.abs-auth-button-secondary:focus, .abs-auth-button-secondary:hover, .abs-auth-button-secondary:visited, .abs-auth-button-secondary:link, .abs-auth-button-secondary:active {
    text-decoration: none !important;
}

a {
    text-decoration: none !important;
}

.invalid-field {
    border-color: red;
}

.invalid-field:focus {
    border-color: red;
    outline: red;
}